.photo-list{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 10px 0;

    @include media-breakpoint-up(lg) {
        flex-flow: row;
        padding: 80px 0;
    }

    &__image-wrapper{
        width: 400px;
        max-width: 100%;
        margin: 30px auto;

        @include media-breakpoint-up(lg) {
            margin: 30px;
            width: 800px;
        }
    }

    @include media-breakpoint-up(lg) {

        &__image-wrapper{
            margin: 0;
        }
        
        &--1 &__image-wrapper{
            max-width: 100%;
        }

        &--2 &__image-wrapper{
            max-width: calc(50% - 15px);
        }

        &--3 &__image-wrapper{
            max-width: calc(33.333% - 15px);
        }

        &--4 &__image-wrapper{
            max-width: calc(25% - 15px);
        }
    }    

    &__image{
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: var(--focal-left) var(--focal-top);
    }
}

.photoblock-generic{
    &--medium{
        @include media-breakpoint-up(lg) {
            max-height: 465px;
        }
    }

    &--small{        
        @include media-breakpoint-up(lg) {
            max-height: 350px;
        }
    }

    @include media-breakpoint-down(md) {
        height: 200px;
    }

    &__photo{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: var(--focal-left) var(--focal-top);
    }
}