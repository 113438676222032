.document-list {
  list-style-type: none;
  padding: 0;
  margin: 15px;
  position: relative;
  // width: max-content;

  margin: 0 30px 30px 30px !important;
  @include media-breakpoint-up(xxl) {
    //margin: 0 auto 0 auto !important;
  }

  :not(:first-child) {
    border-top: 1px solid $brand-primary;
  }

  // &__item {
  //   padding: 15px 0;
  // }

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.document {
  color: black;
  font-size: 1rem;
  font-family: "Gotham Book";
  letter-spacing: 0.083rem;
  line-height: 1rem;
  padding: 20px 30px 20px 0;
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  display: block;

  span {
    position: relative;
    left: 40px;
    width: calc(100% - 40px);
  }

  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }

  &:before {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;

    margin-right: 20px;
    transform: translateX(5px) translateY(1px);
    position: absolute;
    left: 0;
  }

  &--document-pdf {
    &:before {
      content: "\f1c1";
    }
  }

  &--document-generic {
    &:before {
      content: "\f15c";
    }
  }

  &--document-word {
    &:before {
      content: "\f1c2";
    }
  }

  &--document-excel {
    &:before {
      content: "\f1c3";
    }
  }

  &--image {
    &:before {
      content: "\f1c5";
    }
  }

  &--video {
    &:before {
      content: "\f1c8";
    }
  }

  &--website {
    &:before {
      content: "\f0c1";
    }
  }

  &--zip {
    &:before {
      content: "\f1c6";
    }
  }
}
