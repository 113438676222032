.accordion {
    border-top: 1px solid $brand-primary;

    &__item {
        border-bottom: 1px solid $brand-primary;
    }

    &__header {
        margin: 0;
    }

    &__button {
        background-color: transparent;
        border: 0;
        margin-left: 0;
        padding: 8px 0;
        width: 100%;
        text-align: left;
        position: relative;
        padding-right: 43px;
        font-size: 1.5rem;
        font-family: $gotham-book;
        color: $black;



        &:hover {
            cursor: pointer;
        }

        & :focus-visible {
            outline: none;
        }

        & :focus {
            outline: none;
        }

        &:after {
            content: "\f078";
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translateY(-50%) rotate(-180deg);
            font-size: 30px;
            pointer-events: none;
            z-index: 20;
            transition: transform .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        &.collapsed {
            &:after {
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }

    &__collapse {
        //padding: 15px 0 30px;
        &.collapsing {
            transition: height .3s cubic-bezier(0.18, 0.89, 0.32, 1);
        }
    }

    &__text {
        padding: 15px 0 15px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    }

    .button {
        margin-bottom: 15px;
    }

    &__content-wrapper {
        display: flex;
        flex-flow: column;

        @include media-breakpoint-up(xl) {
            flex-flow: row;
        }
    }

    &__image-wrapper {
        width: 700px;
        max-width: 100%;
        margin-bottom: 2rem;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        @include media-breakpoint-up(xl) {
            width: 60%;
            max-width: 700px;
            flex-grow: .8;
            margin-left: 2rem;
        }
    }

    &__image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.accordion-button {
    outline: none;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: 5px auto -webkit-focus-ring-color;
    }
}


.service-drawer-full{
    .accordion__image-wrapper{        
        @include media-breakpoint-up(lg){
            width: 60%;
            max-width: 700px;
            flex-grow: .8;
            margin-left: 2rem;
        }
    }

    .accordion__content-wrapper{
        @include media-breakpoint-up(lg) {
            flex-flow: row;
        }  
    }
}