html {
    font-size: 16px;
    color: $black;
}

h1, .h1 {
    font-family: $interstate-condensed;
    font-size:3.375rem;
    letter-spacing: -.046875rem;
    line-height: 3.375rem;
    text-transform: uppercase;

    &--max, &.max{
        @extend h1;
        letter-spacing: -.0625rem;        
        font-size:3.375rem;
        line-height: 3.375rem;

        @include media-breakpoint-up(md) {
            font-size: 4.5rem;
            line-height: 4.375rem;
        }
    }
    
}

h2, .h2 {
    font-family: $interstate-condensed;
    font-size: 2rem;
    line-height: 2.125rem;
    letter-spacing: -.03125rem;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 3rem;
        line-height: 3.125rem;
    }

    sup{
        font-size: 1rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.75rem;
        }
    }
}

h3, .h3 {
    font-family: $gotham-medium;
    font-size: 1.875rem;
    letter-spacing: -.0625rem;
    line-height: 2.25rem; 
}

// h4, .h4 {
//     font-size: 
//     letter-spacing: 
//     line-height: 
// }

// h5, .h5 {
//     font-size: 
//     letter-spacing: 
//     line-height: 
// }

// h6, .h6 {
//     font-size: 
//     letter-spacing: 
//     line-height: 
// }

.kicker{
    font-family: $gotham-medium;
    font-size: 1rem;
    letter-spacing: .09375rem;
    line-height: 1.5rem;
    color: $yellow;
}

.outlined{
    font-family: $interstate-condensed;
    font-size: 6rem;
    letter-spacing: -.03125rem;
    line-height: 4.4rem;
    text-transform: uppercase;

    color: $brand-primary;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $brand-primary;
}

p, .p {
    font-family: $gotham-book;
    font-size: 1rem;
    letter-spacing: .015625rem;
    line-height: 1.5rem;

    &--big, &.big{
        font-size: 1.25rem;
        letter-spacing: .03125rem;
        line-height: 1.75rem;
    }
}

.subtext{
    font-size: 0.75em;
}

// .disclaimer-text, 
// .disclaimer-text p { 
// }

.giant-text{
    @extend .outlined;
    
    &__wrapper{
        max-width: 60%;

        &--right{
            margin-left: auto;
            margin-right: 0;
        }

        @media (min-width: ($max-width + 200)) {
            margin-left: -100px;

            &--right{
                margin-left: auto;
                margin-right: -100px;
            }
        }

        
    }

    &__container{
        padding: 5% 0 0 0;
        z-index: -1;
        position: relative;
    }
}