.topic-divider{
    display: flex;
    flex-flow: column;
    padding: 40px 0;

    margin: 0 30px !important; 
    @include media-breakpoint-up(xxl) {
        margin: 0 auto 0 auto !important; 
    }

    @include media-breakpoint-up(sm) {
        flex-flow: row;
    }

    &__kicker{          
        @include media-breakpoint-up(sm) {
            width: 25%;
            max-width: 250px;
            min-width: 200px;
        }

        .kicker{
            text-transform: uppercase;
        }
    }

    &__content{
        
        
        @include media-breakpoint-up(lg) {
            &:not(.topic-divider__content--no-kicker){
                padding-left: 60px;
                padding-right:60px;
                margin:0 auto;
            }
        }

        &--centered{
            @extend .topic-divider__content;

            max-width: 1080px;
            width: 100%;
            padding-left: 0;            
        }
        
    }

    &__title{
        @extend h2;
        padding-bottom: 20px;
    }

    &__copy{
        margin-bottom: 40px;
    }


    &--centered{
        @extend .topic-divider;
        display: flex;
        flex-flow: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 20px 0;

        &:before, &:after{
            display: block;
            content: '';
            position: relative;
            width: 80%;
            max-width: 720px;
            border-top: 1px solid $brand-primary;
            height: 0;
            margin: 15px auto 15px auto;
        }

        .topic-divider__title{
            padding-bottom: 0;
            padding-top: 1rem;
        }

        .topic-divider__ctas{
            margin-bottom: 40px;
        }

        .topic-divider__copy{
            margin-top: 1rem;
            margin-bottom: 0px;
        }
    }
}