.button, .btn {
    font-family: $gotham-medium;
    font-size: .875rem;
    letter-spacing: .125rem;
    line-height: 1.25rem;
    color: $black;
    background-color: $yellow;
    border-radius: 50px;
    padding: 8px 30px;
    text-transform: uppercase;
    display: inline-block;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: $brand-primary;
        border-radius: 10rem;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: $black;
        transition: all .3s;
        border-radius: 10rem;
        z-index: -1;
    }

    &:hover {
        color: #fff;
        cursor: pointer;
        color: $white;
        text-decoration: none;
        //border: transparent 1px solid;

        background-color: transparent;
        transition: background-color .3s;

        &:before {
            width: 100%;
        }
    }

    &--outline {
        @extend .button;
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
        text-decoration: none !important;

        &:after {
            background-color: transparent;
        }

        &:before {
            background-color: $brand-primary
        }

        &:hover {
            border-color: transparent;
        }
    }

    &--outline-white {
        @extend .button--outline;

        &:after {
            //background-color: transparent;
        }

        &:before {
            background-color: $white;
        }

        &:hover {
            color: $black;
            border-color: transparent;
        }
    }

    &--outline-black {
        @extend .button--outline;
        border-color: $black;
        color: $black;
        background-color: transparent;

        &:after {
            //background-color: transparent;
        }

        &:before {
            //background-color: $brand-primary
        }

        &:hover {
            color: $white;
            border-color: transparent;
        }
    }

    &--link {
        color: $brand-primary;
        font-family: $gotham-medium;
        font-size: 1rem;
        letter-spacing: .083rem;
        line-height: 1.5rem;
        padding: 8px 30px 8px 0;
        text-transform: uppercase;

        &:after {
            content: "\f0a9";
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            transition: all .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            margin-right: 20px;
            transform: translateX(5px) translateY(1px);
            position: absolute;
        }

        &:hover {
            text-decoration: none;
            color: $yellow;

            &:after {
                transform: translateX(10px) translateY(1px);
            }
        }
    }

    &--link-yellow {
        @extend .button--link;
    }

    &--link-dropdown {
        @extend .button--link;
        color: $white;

        &:after {
            content: "\f078";
            transform: translateX(5px) translateY(1px) rotate(0deg);
            border: 0;
        }

        &:hover, &.show {
            text-decoration: none;
            color: $white;
            cursor: pointer;

            &:after {
                transform: translateX(5px) translateY(21px) rotateX(180deg);
            }
        }
    }
}
