.color{
    &__list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 30px 0 60px;
    }

    &__block{
        display: flex;
        align-items: center;
        padding: 0 60px;
        text-transform: uppercase;
        &:before{            
            content: '';
            width: 260px;
            height: 120px;
            border-bottom: 1px solid black;
        }

        &--yellow:before{
            @extend .color__block;
            background-color:$yellow;
        }

        &--tan:before{
            @extend .color__block;
            background-color:$tan;
        }

        &--tan-light:before{
            @extend .color__block;
            background-color:$tan-light;
        }

        &--black:before{
            @extend .color__block;
            background-color:$black;
        }

        &--gray-500:before{
            @extend .color__block;
            background-color:$gray-500;
        }

        &--white:before{
            @extend .color__block;
            background-color:$white;
        }

        &--red:before{
            @extend .color__block;
            background-color:$red;
        }

        &--red-dark:before{
            @extend .color__block;
            background-color:$red-dark;
        }

        &--orange:before{
            @extend .color__block;
            background-color:$orange;
        }

        &--orange-dark:before{
            @extend .color__block;
            background-color:$orange-dark;
        }

        &--mustard:before{
            @extend .color__block;
            background-color:$mustard;
        }

        &--mustard-dark:before{
            @extend .color__block;
            background-color:$mustard-dark;
        }

        &--green:before{
            @extend .color__block;
            background-color:$green;
        }

        &--green-dark:before{
            @extend .color__block;
            background-color:$green-dark;
        }

        &--blue:before{
            @extend .color__block;
            background-color:$blue;
        }

        &--blue-dark:before{
            @extend .color__block;
            background-color:$blue-dark;
        }

        &--gray-dark:before{
            @extend .color__block;
            background-color:$gray-dark;
        }
    }
}

.anchor-list{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}