.service-drawer {
  display: inline-block;
  width: 100%;
  padding: 30px;
  vertical-align: top;

  @include media-breakpoint-up(lg) {
    width: 49%;
    max-width: calc($max-width/2) !important;
    padding: 0 55px 80px 30px;
    //float: left;
  }

  @include media-breakpoint-up(xxl) {
    width: 49.8%;
    padding: 0 55px 80px 0;
  }

  &:nth-child(odd) {
    @include media-breakpoint-up(lg) {
      padding: 0 55px 80px 30px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 55px 80px 0;
    }
  }

  &:nth-child(even) {
    @include media-breakpoint-up(lg) {
      padding: 0 30px 80px 55px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 0 80px 55px;
    }
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    max-height: 662px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: calc(50vw - 110px);
    }
  }

  &__image {
    object-fit: cover;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    object-position: var(--focal-left) var(--focal-top);
  }

  &__title {
    margin-top: 40px;
    font-size: 2.625rem;
    font-family: $interstate-condensed;
    line-height: 2.625rem;
    letter-spacing: -0.084rem;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
  }
}

.service-drawer-large {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 30px;
  position: relative;
  margin: 30px 0;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    margin: 80px 0;
  }

  &:after {
    content: "";
    display: block;
    background-color: $white;
    width: 100%;
    height: 90%;
    position: absolute;
    left: 0;
    top: 10%;
    z-index: -1;

    @include media-breakpoint-up(lg) {
      top: 0;
      height: 100%;
      width: 75%;
      left: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 60px 0;
    flex-flow: row-reverse;
    overflow-x: auto;

    &--reverse {
      flex-flow: row;

      &:after {
        left: unset;
        right: 0;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    padding: 0;
  }

  &__icon-wrapper {
    width: 150px;
    height: 150px;
    margin-bottom: 60px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: 662px;
    max-width: 500px;

    @include media-breakpoint-up(lg) {
      margin: 60px 0;
      width: 40%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-position: var(--focal-left) var(--focal-top);
    object-fit: cover;
  }

  &__content {
    padding: 60px 0;
    @include media-breakpoint-up(lg) {
      width: 60%;
      padding: 80px;
    }
  }

  &__title {
    @extend .h2;
    font-size: 2.625rem;
    line-height: 2.625rem;
    letter-spacing: -0.0844rem;
  }

  &__subtitle {
    @extend .h5;
  }
}

.service-drawer-full {
  @extend .service-drawer;
  padding: 30px;

  &__title {
    @extend .h2;
    font-size: 2.625rem;
    line-height: 2.625rem;
    letter-spacing: -0.0844rem;
  }

  &__subtitle {
    @extend .h4;
  }

  &__content {
    @media (min-width: $max-width) {
      margin: 0 20px;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    max-width: $max-width !important;
  }

  @media (min-width: $max-width + 100) {
    padding: 0 0 80px 30px;

    &:nth-child(odd) {
      padding: 0 0 80px 0;
    }
  }

  @include media-breakpoint-up(xxl) {
    width: 100%;
    padding: 0 0 80px 30px;
  }
}
