.header{
    background-color: $tan;       
    z-index: 99;
    position: relative;
}

.logo{
    width: 100%;
}

.nav{
    &__link{
        font-family: $gotham-medium;
        font-size: 1.25rem;
        line-height: 3rem;
        letter-spacing: 0.38px;
        text-transform: uppercase;
        position: relative;

        @include media-breakpoint-up(sm) {   
            font-size: 1.5rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 0.75rem;
            line-height: 0.75rem;
            letter-spacing: 0.03125rem;
        }

        &:before{
            content: '';
            display: block; 
            position: absolute;
            height: 2px; 
            background-color: $brand-primary;
            width: calc(100% - 1rem);
            top: 25px;

            pointer-events: none;
            transform: scaleX(0);
            transform-origin: 100% 50%;
            transition: transform .3s;
        }
        
        // &--active{            
        // }

        @include media-breakpoint-up(lg) {
            &--active, &:hover, &.active{
                &:before{
                    transform: scaleX(1);
                    transform-origin: 0 50%;
                    transition: transform .3s;
                }
            }
        }
    }   

    &-child{
        display: flex;
        align-items: center;
        

        .nav-link{
            text-transform: none!important;
        }

        img{
            width: 30px;
            height: 30px;
            margin-right: 12px;
        }
    }

    &__link-wrapper{
        display: flex;
        align-items: baseline;

        &:hover{
            text-decoration: none;
        }
    }
}

footer{
    .nav{
        &__link{
            &:before{
                top: unset;
                bottom:8px;
                background-color: transparent;
            }
        }
    }
}

.nav-link {


    &__dropdown {
        align-items: center;
        color: $black; //$navbar-light-color;
        padding-left: 20px;
        font-size: 0;

        @include media-breakpoint-up(lg) {
            display: flex;
            padding-left: 0;
            color: $navbar-light-color;
        }

        &:hover {
            color: $nav-tabs-link-active-color;
        }

        &:focus {
            color: $navbar-light-hover-color;
        }


        &:after {
            transition: .15s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform: rotate(0deg);
            font-size: 30px;

            @include media-breakpoint-up(sm) {
                //font-size: 30px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 12px;
            }
        }

        &.show:after {
            transform: rotate(-180deg);
        }
    }
}

.navbar {
    max-width: $max-width;
    margin: 0 auto;
    position: initial;

    @include media-breakpoint-up(lg) {
        background: $tan;
    }

    &-brand {
        max-width: 170px;
    }

    &-collapse {
        margin: 20px 0px;

        @include media-breakpoint-up(lg) {
            align-items: center;
            justify-content: end;
        }
    }

    &-toggler {
        border: 0;

        &:not(.collapsed) {
            .navbar-toggler-icon {
                background: url
            }
        }

        &:focus {
            /* outline: none;*/
            outline: 1px solid $navbar-light-hover-color;
        }
    }

    .nav-link {
        color: $black !important;

        @include media-breakpoint-up(lg) {
            color: $nav-tabs-link-active-color;
        }
    }
}

.dropdown {

    @include media-breakpoint-up(lg) {
        display: flex;
        position: initial;

        &:hover .dropdown-menu, .dropdown-menu:hover {
            display: block;
            transform: translateY(0%);
            transform-origin: 50% 100%;
            transition: transform 0.3s, opacity 0.3s;
            opacity: 1;
            pointer-events: unset;
        }
    }

    &-menu:not(.map-menu) {
        background-color: transparent;
        border: 0px;
        padding-top: 0;

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-top: 0px;
            border-radius: 0;
            display: block;
            position: relative;
            z-index: 50;
            transform-origin: 100% 100%;
            transition: transform .4s, opacity .4s;
            opacity: 0;
            pointer-events: none;
            padding-top: 26px;
            margin-top: -30px;

            &.show {
                display: block;
                transform-origin: 50% 100%;
                transition: transform .4s, opacity .5s;
                opacity: 1;
                pointer-events: unset;
            }
        }

        .nav__link {
            font-family: $gotham-book;
            font-size: 1.25rem;
            line-height: 2.25rem;

            @include media-breakpoint-up(lg) {
                font-size: 2rem;
                line-height: 3rem;
                letter-spacing: -0.083125rem;
                padding-left: 0 !important;
                padding-right: 0 !important;

                &:hover, .active {
                    //text-decoration: underline;
                    color: $brand-primary !important;

                    &:before {
                        content: none;
                    }
                }
            }

            &-wrapper {
                @include media-breakpoint-up(lg) {
                    display: flex;
                    align-items: baseline;
                }
            }

            &--icon {
                font-size: 1rem;
                line-height: 1.875rem;

                @include media-breakpoint-up(lg) {
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                    letter-spacing: initial;
                    padding: 9px 0;
                }
            }

            &--view-all {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                    font-size: 0.75rem;
                    line-height: 1.5rem;
                    letter-spacing: initial;
                    text-decoration: underline;
                    margin-left: 16px;
                }
            }

            &--grand {
                font-size: 16px;
                margin-left: 64px;
                line-height: 16px;

                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }
            }
        }
    }

    .nav__link, .nav-link__dropdown {
        outline: none;

        &.dropdown-toggle-link {
            display: inline;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
    }

    &__links {
        @include media-breakpoint-up(lg) {
            max-width: 50%;
            width: 50%;
            margin-left: 60px;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 60px;
        }

        @include media-breakpoint-up(xxl) {
            margin-left: 120px;
        }
    }

    &__wrapper {
        background-color: $tan;
    }

    &__container {
        max-width: $max-width;

        @include media-breakpoint-up(lg) {
            padding: 60px;
            display: flex;
            margin: 0 auto;
            padding-bottom: 40px;
        }
    }

    &__image {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            max-width: 50%;
            object-fit: cover;
        }
    }

    .dropdown-toggle {
         &:hover,&:active,
         &:target{
            outline: none;
        }

        &:focus-visible
        {
             outline: 1px solid $navbar-light-hover-color;
        }
    }
}

.navbar-toggler{ 

    .hamburger .line{
        width: 28px;
        height: 2px;
        background-color: $black;
        display: block;
        margin: 8px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    &:not(.collapsed){

        .hamburger .line{
            height: 3px;
            margin: 10px auto;
        }

        #hamburger .line:nth-child(2){
            opacity: 0;
        }
        
        #hamburger .line:nth-child(1){
            -webkit-transform: translateY(13px) rotate(45deg);
            -ms-transform: translateY(13px) rotate(45deg);
            -o-transform: translateY(13px) rotate(45deg);
            transform: translateY(13px) rotate(45deg);
        }
        
        #hamburger .line:nth-child(3){
            -webkit-transform: translateY(-13px) rotate(-45deg);
            -ms-transform: translateY(-13px) rotate(-45deg);
            -o-transform: translateY(-13px) rotate(-45deg);
            transform: translateY(-13px) rotate(-45deg);
        }
    }
}

.grp-with-icons.nav-children{
    display: flex;
    flex-flow: column;

    @include media-breakpoint-up(xl){
        flex-flow: column;
        flex-wrap: wrap;        
        max-height: 200px;

    }
}

.nav-child{
    
    &__wrapper{
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        @include media-breakpoint-up(xl) {
            width: 50%;
        }
        // &--has-descendants{
        //     padding-right: 20px;
        // }
    }

    &__singles-list{
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
        }
    }
}

.nav-grandchildren{    
    @include media-breakpoint-up(lg) {
        div:last-child{
            margin-bottom: 40px;
        }
    }
}

