.footer {
  background-color: black;
  color: $gray-500;

  &__logo-wrapper {
    max-width: 240px;
    display: block;
  }

  &__row {
    padding-top: 60px;
  }

  &__copyright {
    margin: 40px 0 20px;
    font-size: 0.7rem;
    text-align: center;
  }

  &__background-graphic {
    width: 100%;
  }

  &__list {
    margin-top: 50px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__item {
    font-family: $gotham-medium;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 0.5px;
    color: $white;
    padding: 12px 0px;

    &:hover {
      color: $brand-primary;

      &:before {
        width: 100%;
      }
    }
  }

  &__item-wrapper {
    @include media-breakpoint-up(lg) {
      padding: 0 10px;
    }
    @include media-breakpoint-up(xl) {
      min-width: 120px;
    }
  }

  &__sub-item {
    color: $gray-500;
    padding: 6px 0px;
    text-transform: none !important;
    font-family: $gotham-medium;
    font-size: 1.25rem;
    line-height: 1.25rem;
    letter-spacing: 0.38px;
    position: relative;
    display: block;

    @include media-breakpoint-down(md) {
      &.nav__link {
        line-height: 1.25rem;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: 0.75rem;
      line-height: 0.75rem;
      letter-spacing: 0.03125rem;
    }

    &:before {
      display: none;
    }

    &:hover {
      color: $brand-primary;
    }
  }
}

.social-links {
  padding: 40px 0;
  &__icon {
    font-size: 26px;
    color: $brand-primary;
    margin-right: 16px;

    &:hover {
      color: $white;
    }
  }
}
