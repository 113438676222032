.rt-block {
    padding: 30px 0;

    @include media-breakpoint-up(md) {
        padding: 60px 0;
    }

    p, h1, h2, h3,
    h4, h5, h6 {
        padding: 0 30px;
    }

    ol, ul {
        margin-left: 30px;
        margin-right: 30px;
    }

    &--nospacing {
        @extend .rt-block;
        padding-top: 0;
        padding-bottom: 0;
    }

    &--form {
        @extend .rt-block;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}
