.testimonial{    
    display: flex;
    position: relative;
    padding: 80px 0;
    flex-flow: column-reverse;    

    &:before{
        content: "";
        background: url('../images/quote.svg');
        position: absolute;
        left: 60px;
        //top: 10px;
        top: 30px;
        font-size: 160px;
        z-index: 20;
        width: 176px;
        height: 160px;
        background-size: contain;
        background-repeat: no-repeat;

        @include media-breakpoint-up(lg) {
            top: 60px;
        }
    }

    &:after{
        content: '';
        top: 120px;
        display: block;
        position: absolute;
        width: 92%;
        height: calc(100% - 170px);
        z-index: 2;
        background-color: $white;

        @include media-breakpoint-up(lg) {
            height: calc(100% - 320px);
            width: calc(58% + 60px);
            top:50%;
            transform: translateY(-50%);
        }
    }

    @include media-breakpoint-up(lg) {
        flex-flow: row;

        &--reverse{
            flex-flow: row-reverse;

            &:before{
                left: 44%;
            }
        }
    }

    &__wrapper{
        margin: 0;

        @include media-breakpoint-up(lg) {
            margin: 60px 0;
        }
    }

    &__image-wrapper{
        @include media-breakpoint-down(md) {
            max-width: 500px;
        }
    }

    &__left{
        padding: 30px;
        z-index: 3;
        width: 92%;
        padding-bottom: 240px;
        padding-bottom: 80px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        //color: $white;
        position: relative;

        @include media-breakpoint-up(lg) {
            z-index: 3;
            width: 58%;
            padding: 60px 120px 60px 60px;
        }
    }

    &__right{
        z-index: 4;
        width: 100%;
        @include media-breakpoint-up(lg) {
            z-index: 1;
            width: 42%;
            
        }
    }

    &__title{
        margin-bottom: 30px;
    }

    &__text{
        p{
            font-family: $gotham-book;
            font-size:1.5rem;
            line-height: 2.05rem;
            letter-spacing: -0.0625rem;

            @include media-breakpoint-only(md) {
                font-size:1.875rem;
                line-height: 2.25rem;
                letter-spacing: -0.0625rem;
            }            
        }

        div{
            margin-bottom: 10px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 50px;
            }
        }

        &--long p{
            font-size:1.5rem;
            line-height: 2rem;

            @include media-breakpoint-only(lg) {
                font-size: 1.25rem;
                line-height: 1.75rem;
            }            
        }
    }

    &__author{
        font-family: 'Gotham Medium Italic';
        font-size:1rem;
        line-height: 1.5rem;
        margin-bottom: 10px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }
    }

    &__image{
        width: 80%;
        max-width: 780px;
        height: 80vw;
        max-height: 490px;
        margin-top: 77px;
        object-fit: cover;
        float: right;
        object-position: var(--focal-left) var(--focal-top);

        @include media-breakpoint-up(lg) {
            max-height: 777px;
            width: 100%;
            margin-top: 0;
            float: none;
        }
    }

    &__links{
        position: absolute;
        bottom: 50px;
        @include media-breakpoint-up(lg) {
            bottom: 120px;
        }

        &--reverse{            
            @include media-breakpoint-up(lg) {
                right: 30px;    
            }
        }
    }
}

.testimonials{   
    margin: 60px auto 200px auto;

    &__controls{
        
        position: absolute;
        right: 0;
        display: flex;
        bottom: -30px;
        @include media-breakpoint-up(lg) {
            bottom: 80px;
            right: -60px;

            &--reverse{
                left: -60px;
                right: unset;
            }
        }
    }

    &__control{
        position: relative;
        width: 60px;
        height: 60px;
        margin-top:0;
        transition: all .15s linear;
        background-color: $black;

        &:after{
            font-size: 1.75rem;
            color: $white;
        }

        &:hover{
            background-color: $yellow;
            &:after{color: $white;}
        }
        &--prev{
            left:0;
        }

        &--next{
            right: 0;
        }
    }

    &--singular &__controls{
        display: none;
    }
}
