.full-width-cta {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 !important;

    @include media-breakpoint-up(md) {
        margin: 0 30px !important;
    }

    @include media-breakpoint-up(xxl) {
        margin: 0 auto 0 auto !important;
    }

    .button {
        margin: 30px;
    }

    &__strip {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid $brand-primary;
        flex: 1;
    }

    &--back-to-top {
        @extend .full-width-cta;
        margin: 0px auto 120px auto !important;
    }

    &__back-to-top {
        position: relative;
        color: black;
        padding-top: 90px;
        font-family: $interstate-condensed;
        font-size: 2rem;
        letter-spacing: -0.0019rem;
        line-height: 2.625rem;
        text-transform: uppercase;

        &:before {
            content: "\f0aa";
            color: $brand-primary;
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            font-size: 30px;
            left: 50%;
            pointer-events: none;
            z-index: 20;
            transition: all .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        &:hover {
            color: $brand-primary;
            text-decoration: none;

            &:before {
                transform: translateY(-90%) translateX(-50%);
            }
        }
    }
}
