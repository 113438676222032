// Colors
// $green: #009A44;
// $pink: #A52E58;
// $red: #EB3330;
// $yellow: #FEA527;
// $light-blue: #0091BD;
// $dark-blue: #0033A0;
// $lightest-gray: #F6F6F6;
// $light-gray: #D8D8D8;
// $gray: #CECCCC;
// $text-gray: #7F8286;
// $card-gray: #e9e9e9;
// $dark-gray: #282828;
// $darker-gray: #232323;

$yellow: #F5A71C;
$orange: #B15526;
$tan: #F9F1DB;
//$tan-light: #FFFCF3;
$tan-light: #fff8e8;
$black: black;
$gray-500:#808080;
$gray-800: #393732;
$gray-900: #222222;
$white: white;

$text-default: $gray-900;

$brand-primary: $yellow;
$background: $tan-light;

$max-width : 1440px;

$interstate-condensed : "interstate-condensed";
$gotham-medium : 'Gotham Medium';
$gotham-book : 'Gotham Book';

//Secondary Colors
$red : #A71930;
$red-dark : #782327;
$orange : #B2541A;
$orange-dark : #8D3C1E;
$mustard : #A17700;
$mustard-dark : #6C4D23;
$green : #6A7F10;
$green-dark : #434F0E;
$blue : #6A94C1;
$blue-dark : #316186;
$gray-dark : #393732;


//breakpoints for Maps
$mediaBreakPointDesktop: 992px;