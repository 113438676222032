.ff{
    @extend .row;    
    align-items: center;
    margin:0;
    padding: 20px 0;
    justify-content: center;   

    &--reverse{
        @include media-breakpoint-up(lg) {
            flex-flow: row-reverse;
        }
    } 

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: stretch;
        padding: 100px 0;
    }

    &__image-wrapper{
        @extend .col-lg-6;
        min-height: 300px;
        display: flex;
        justify-content: center;
        padding: 0;
        overflow: hidden;

        @include media-breakpoint-up(sm){
            min-height: 400px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 900px;
            //justify-content: end;
        }

        &--small{
            height: 94vw;
            min-height: 320px;
            max-height: 500px;
            max-width: 660px;
            @include media-breakpoint-up(sm) {
                min-height: 475px;
            }
            @include media-breakpoint-up(lg) {
                min-height: 620px;
                max-height: none;
                height: auto;
            }
        }

        &--hero{
            overflow: visible;
        }
    }

    &__image{
        width: 120%;
        height: 100%;
        object-fit: cover;
        position: absolute;  
        
        @include media-breakpoint-up(lg) {
            width: 120%;
        }
        
        object-position: var(--focal-left) var(--focal-top);

        &--hero{
            width: 100%;            
            height: 100%;
        }
    }

    &__content-wrapper{
        @extend .col-lg-6;
        padding: 30px;       
        display: flex;
        flex-flow: column;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            padding: 60px;    
        }

        &--hero{
            justify-content: start;
            background-color: $orange;
            color: $white;

            a{
                color: $white;
                text-decoration: underline;
            }
        }

        &--hero-red{            
            background-color: $red;            
        }
    
        &--hero-dark-red{
            background-color: $red-dark;
        }
    
        &--hero-orange{
            background-color: $orange;
        }
    
        &--hero-dark-orange{
            background-color: $orange-dark;
        }
    
        &--hero-mustard{
            background-color: $mustard;
        }
    
        &--hero-brown{
            background-color: $mustard-dark;
        }
    
        &--hero-green{
            background-color: $green;
        }
    
        &--hero-dark-green{
            background-color: $green-dark;
        }
    
        &--hero-blue{
            background-color: $blue;
        }
    
        &--hero-dark-blue{
            background-color: $blue-dark;
        }
    
        &--hero-dark-gray{
            background-color: $gray-dark;
        }

        &--hero-black{
            background-color: $gray-dark;
        }
    }

    &__content{
        @include media-breakpoint-up(lg) {
            max-width: 660px;
        }
    }

    &__kicker{
        margin-bottom: 9px;
    }

    &__title{
        margin-bottom: 22px;
    }

    &__text{
        margin-bottom: 70px;
    }
}