html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
}

:target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
}

body{
    background-color: $background;
    position: relative;
}

main{
    z-index: 0;
    //overflow: hidden; //needed to prevent side scrolling with AOS elements, breaks landing video module.     
}

.umb-block-list{
    position: relative;
    & > div:not(.fullwidth){
        max-width: $max-width;
        margin: 0 auto 0 auto; 
    }

    & > div.rt-block{
        margin: 0 30px; 
        @include media-breakpoint-up(xxl) {
            margin: 0 auto 0 auto; 
        }
    }
}

hr{
    border-top: 1px solid $brand-primary;
    margin: 30px 0;
}


/*Scrollbar*/

/*html {
    --scrollbarBG: #EBEBEB;
    --thumbBG: #393732;
}*/

*::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: $gray-800 $tan-light;
}

*::-webkit-scrollbar-track {
    background: $tan-light;
}

*::-webkit-scrollbar-thumb {
    background-color: $gray-800;
    /*border-radius: 6px;*/
    border: 3px solid $tan-light;
}


/*End of scrollbar*/
