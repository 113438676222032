.service-feature{
    width: 100%;
    display:flex;
    flex-flow: column;
    margin: 60px 0;
    
    &--bottom{
        @include media-breakpoint-up(lg) {
            flex-flow: column-reverse;
        }
    }

    @include media-breakpoint-up(lg) {
        width: 50%;
        align-items: center;

    }    

    &__image-wrapper{
        position: relative;
        width: 100%;
        height: 75%;
        max-height: 720px;
        z-index: 5;

    }

    &__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: var(--focal-left) var(--focal-top);
    }

    &__content{
        position: relative;
        margin: -15% 30px 0 30px;
        width: calc(100% - 60px);
        padding: 30px;
        background: white;
        z-index: 10;

        @include media-breakpoint-up(lg) {
            margin: -30% 60px 0 60px;
            padding: 60px;
            width: calc(100% - 120px);
        }
        

        &--bottom{
            @include media-breakpoint-up(lg) {
                margin-top: 0;
                margin-bottom: -30%;
            }            
        }
    }

    &__icon-wrapper{
        position: relative;
        width: 96px;
        height: 96px;
        margin-bottom: 30px;
    }

    &__icon{
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__title{
        @extend .h2;
        font-size: 2.625rem;
        line-height: 2.625rem;
        letter-spacing: -0.0844rem;
        margin-bottom: 26px;
        font-weight: 600;
    }

    &__text{
        margin-bottom: 26px;
    }
}

.service-features{
    display:flex;
    flex-flow: row;
    flex-wrap: wrap;
}