.anchorlink{
    position: sticky;
    z-index: 96;
    top: 0;
    width: 100vw;
    left: calc(-1 * (100vw - 100%) / 2);
    background: $tan;
    background: -moz-linear-gradient(90deg, $brand-primary 50%, $tan 50%);
    background: -webkit-linear-gradient(90deg, $brand-primary 50%, $tan 50%);
    background: linear-gradient(90deg, $brand-primary 50%, $tan 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$brand-primary,endColorstr=$tan,GradientType=1);    

    &__list{
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: $max-width;        
        flex-flow: row-reverse;
        padding: 0px 10px;
        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }

    &__item{
        color: $black;
        font-size: 0.75rem;
        line-height: 0.75rem;
        letter-spacing: 0.5px;
        flex:1;
        text-align: center;
        padding: 3px 2px;
        
        background: $tan;
        position: relative;
        transition: all .3s;
        z-index: 10;

        font-family: $interstate-condensed;
        font-size: .70rem;
        line-height: 2rem;
        letter-spacing: 0.03125rem;
        

        @include media-breakpoint-up(sm) {
            padding: 3px;
            font-size: 1.125rem;
        }

        &:after{
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            left: 0;
            background-color: $brand-primary;
            top: 0;
            z-index: -1;
            transition: .3s;
        }

        &:before{
            content: none !important;
        }        

        &:hover, &.active{
            color: $black;            

            &:after, 
            ~ .anchorlink__item:after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                right: 0;
                background-color: $brand-primary;
                top: 0;
                z-index: -1;
                transition: .3s;
            }
        }

        &:hover{
            ~ .anchorlink__item:after{
                transition: 0s !important;
            }
        }
    }
}