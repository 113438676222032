.img-card{
    position: relative;
    margin: 60px auto !important;   

    &:after{
        display: block;
        content: '';
        position: absolute;
        background-color: $black;
        z-index: 0;
        width: 100%;
        height: calc(100%);
        top: 70px;

        @include media-breakpoint-up(lg) {
            top: 0;
            right: 0;
            width: 75%;
        }
    }    

    @include media-breakpoint-up(lg) {
        display: flex;

        &--reverse{
            flex-flow:row-reverse;

            &:after{
                right: unset;
                left: 0;
            }
        }
    }

    &__image-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        position: relative;
        
        @include media-breakpoint-up(lg) {
            width: 50%;
            padding: 50px 0;
        }
    }

    &__image{
        max-width: calc(100% - 60px);
              
        
        @include media-breakpoint-up(lg) {
            max-width: calc(100% - 30px);
            width: 100%;
            object-fit: cover;
            height: 100%;
            margin-right: 30px;  

            object-position: var(--focal-left) var(--focal-top);

            &--reverse{
                margin-right: 0;
                margin-left: 30px;
            }
        }
    }

    &__content{
        z-index: 1;
        position: relative;
        color: white;
        padding: 30px;

        @include media-breakpoint-up(lg) {
            width: 50%;
            display: flex;
            flex-flow: column;
            justify-content: center;

            &--reverse{
                margin-left: 30px;
            }
        }
    }

    &__title{
        margin-bottom: 30px;
    }

    &__text{
        margin-bottom: 60px;
    }

    &--red{
        &:after{
            background-color: $red;
        }
    }

    &--dark-red{
        &:after{
            background-color: $red-dark;
        }
    }

    &--orange{
        &:after{
            background-color: $orange;
        }
    }

    &--dark-orange{
        &:after{
            background-color: $orange-dark;
        }
    }

    &--mustard{
        &:after{
            background-color: $mustard;
        }
    }

    &--brown{
        &:after{
            background-color: $mustard-dark;
        }
    }

    &--green{
        &:after{
            background-color: $green;
        }
    }

    &--dark-green{
        &:after{
            background-color: $green-dark;
        }
    }

    &--blue{
        &:after{
            background-color: $blue;
        }
    }

    &--dark-blue{
        &:after{
            background-color: $blue-dark;
        }
    }

    &--dark-gray{
        &:after{
            background-color: $gray-dark;
        }
    }

    &--black{
        &:after{
            background-color: $gray-dark;
        }
    }
}