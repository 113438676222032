// Fonts
//Font Options Needed
//interstate-compressed


@font-face {
    font-family: 'Gotham Black';
    src: local('Gotham Black'), url('../fonts/hco_fonts/woff2/Gotham-Black_Web.woff2') format("woff2"), url('../fonts/hco_fonts/woff/Gotham-Black_Web.woff') format("woff"),;
}

@font-face {
    font-family: 'Gotham Black Italic';
    src: local('Gotham Black Italic'), url('../fonts/hco_fonts/woff2/Gotham-BlackItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-BlackItalic_Web.woff') format('woff'),;
}

@font-face {
    font-family: 'Gotham Bold';
    src: local('Gotham Bold'),url('../fonts/hco_fonts/woff2/Gotham-Bold_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Bold_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Bold Italic';
    src: local('Gotham Bold Italic'), url('../fonts/hco_fonts/woff2/Gotham-BoldItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-BoldItalic_Web.woff') format('woff'),;
}

@font-face {
    font-family: $gotham-book;
    src: local($gotham-book),url('../fonts/hco_fonts/woff2/Gotham-Book_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Book_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Book Italic';
    src: local('Gotham Book Italic'), url('../fonts/hco_fonts/woff2/Gotham-BookItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-BookItalic_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Light';
    src: local('Gotham Light'), url('../fonts/hco_fonts/woff2/Gotham-Light_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Light_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Light Italic';
    src: local('Gotham Light Italic'), url('../fonts/hco_fonts/woff2/Gotham-LightItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-LightItalic_Web.woff') format('woff');
}

@font-face {
    font-family: $gotham-medium;
    src: local( $gotham-medium), url('../fonts/hco_fonts/woff2/Gotham-Medium_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Medium_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Medium Italic';
    src: local('Gotham Medium Italic'), url(../fonts/hco_fonts//woff2/Gotham-MediumItalic_Web.woff2) format('woff2'), url('../fonts/hco_fonts/woff/Gotham-MediumItalic_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Thin';
    src: local('Gotham Thin'), url('../fonts/hco_fonts/woff2/Gotham-Thin_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Thin_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Thin Italic';
    src: local('Gotham Thin Italic'), url('../fonts/hco_fonts/woff2/Gotham-ThinItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-ThinItalic_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Ultra';
    src: local('Gotham Ultra'), url('../fonts/hco_fonts/woff2/Gotham-Ultra_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-Ultra_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Ultra Italic';
    src: local('Gotham Ultra Italic'), url('../fonts/hco_fonts/woff2/Gotham-UltraItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-UltraItalic_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham XLight';
    src: local('Gotham XLight'),url('../fonts/hco_fonts/woff2/Gotham-XLight_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-XLight_Web.woff') format('woff');
}

@font-face {
    font-family: 'Gotham XLight Italic';
    src: local('Gotham XLight Italic'),url('../fonts/hco_fonts/woff2/Gotham-XLightItalic_Web.woff2') format('woff2'), url('../fonts/hco_fonts/woff/Gotham-XLightItalic_Web.woff') format('woff');
}