.yellow{
    color: $yellow;
}

.white{
    color: $white;
}

.black{
    color: $black;
}

.text-center{
    text-align: center;
}

.bg-black{
    background-color: $black;
}

.bg-orange{
    background-color: $orange;
}