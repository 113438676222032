// .map{
//     width: 100%;
//     height: 56vw;
// }

// .tab-content > .tab-pane:not(.active) {
//     display: block;
//     height: 0;
//     overflow-y: hidden;
// }

/*$mediaBreakPointDesktop: 992px;*/

.map-page-content .container-fluid {
	padding: 0;
}

#world{
	overflow:hidden;
}

#vmap, #vmap-ca, #vmap-mx, #world-map {
	height: 55vh;
	margin: 30px 0;
	max-height: 1000px;

	@include media-breakpoint-up(lg) {
		height: 60vw;
		margin: 0;
		display: flex;
		flex-flow: column-reverse;
	}

	.jvectormap-region[data-active=true] {
		cursor: pointer;
		fill: $brand-primary;
	}

	.jvectormap-region[data-active=true]:hover {
		opacity: .8;
	}
}

#world-map {
	margin-bottom: 1rem;
	margin-top: -12rem;

	.jvectormap-zoomin, .jvectormap-zoomout, .jvectormap-move, .jvectormap-back {
		position: absolute;
		top: 95%;
		width: 20px;
		height: 20px;
		line-height: 15px;
		text-align: center;
		color: #ffbc00;
		font-size: 20px;
		display: block;

		@include media-breakpoint-down(sm) {
			display: block;
			top: 90%;
		}
	}

	.jvectormap-zoomin {
		left: 0px;
		cursor: pointer;
	}

	.jvectormap-zoomout {
		left: 30px;
		cursor: pointer;
	}
}

#vmap-ca {
	height: 60vw;
	margin: 30px 0;
	max-height: 1000px;

	@include media-breakpoint-up(lg) {
		margin: 0;
		display: flex;
		flex-flow: column-reverse;
	}

	.jvectormap-region[data-active=true] {
		cursor: pointer;
		fill: $brand-primary;
	}

	.jvectormap-region[data-active=true]:hover {
		opacity: .8;
	}

	.jvectormap-zoomin, .jvectormap-zoomout, .jvectormap-move, .jvectormap-back {

		@include media-breakpoint-down(sm) {
			display: block;
			top: 85%;
		}
	}
}

.jvectormap-label {
	position: absolute;
	background: white;
	color: #ffbc00;
	font-family: "proxima-nova";
	text-transform: uppercase;
	padding: 5px 5px;
	font-weight: 300;
	border-radius: 50px;
	border-bottom-right-radius: 0;
	-moz-box-shadow: 0 0 20px rgba(0,0,0,.3);
	-webkit-box-shadow: 0 0 20px rgba(0,0,0,.3);
	box-shadow: 0 0 20px rgba(0,0,0,.3);
	display: none;
}

.jvectormap-zoomin, .jvectormap-zoomout, .jvectormap-move, .jvectormap-back {
	position: absolute;
	top: 10px;
	width: 20px;
	height: 20px;
	line-height: 15px;
	text-align: center;
	color: #ffbc00;
	font-size: 20px;
	display: none;

	@include media-breakpoint-down(sm) {
		display: block;
		top: 60%;
	}
}

.jvectormap-zoomin {
	left: 0px;
	cursor: pointer;
}

.jvectormap-zoomout {
	left: 30px;
	cursor: pointer;
}

.jvectormap-move {
	left: 60px;
}

.jvectormap-back {
	left: 90px;
	cursor: pointer;
}

.map-icon {
	background: url('../images/group-icon-sprite.png') 0 0 repeat;
	width: 50px;
	height: 50px;
	float: left;
}

.legend {
	margin: 0 auto 20px;
	padding: 20px;
	background: $white;
	flex-flow: column;
	width: 100%;

	@include media-breakpoint-up(md) {
		flex-flow: row;
		justify-content: space-around;
		align-items: center;
	}


	&__copy {
		padding-bottom: 40px;

		@include media-breakpoint-up(md) {
			padding-bottom: 0;
		}
	}

	&__key {
		display: flex;
		justify-content: space-evenly;
		width: 100%;

		@include media-breakpoint-up(md) {
			width: 50%;
		}
	}
}

.legend-key {
	float: left;
	text-align: center;
	width: 25%;
	//margin-bottom:20px;
}

.legend-key .map-icon {
	margin: 0 auto;
	float: none;
}

.legend-key-name {
	padding-top: 8px;
	font-size: 16px;
	font-weight: 300;
	text-transform: uppercase;
}

.map-icon-ethanol {
	background-position: -50px 0;
}

.map-icon-plant {
	background-position: -100px 0;
}

.map-icon-rail {
	background-position: -150px 0;
}

.map-icon-turf {
	background-position: -200px 0;
}

.map-icon-retail {
	background-position: -250px 0;
}
///LIST
.panel-group .facility.panel + .panel, .panel-group .facility {
	margin: 0;
	border-radius: 0;
	border: none;
}

.panel-group {
	margin: 0;
}

#facilities {
	padding: 0;
}

.facility-summary {
	cursor: pointer;

	@include media-breakpoint-down(sm) {
		position: relative;

		&:after {
			content: "+";
			display: block;
			color: $white;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 0;
			font-size: 3.375rem;
			line-height: 2rem;
			padding: 0 15px 11px 3px;
			margin: 10px;
			transform: rotate(225deg);
			transition: transform .3s ease-out;
		}

		&.collapsed {
			&:after {
				transform: rotate(0deg);
			}
		}
	}
}

.facility-toggle {
	text-align: right;
	color: #dddddd;
	font-size: 120px;
	font-weight: 100;
	vertical-align: baseline;
	padding: 92px 80px 0;
}


.facility-toggle .ss-icon {
	float: right;
	width: 78px;
	height: 78px;
	line-height: 49px;
	padding: 0;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}

.facility-summary:not(.collapsed) .facility-toggle .ss-icon {
	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	-o-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	transform: rotate(225deg);
}

.facility-overview {
	background: $brand-primary;
	color: white;
	padding: 96px 60px;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;
}
// .facility.even{
// 	background:#fafafa;
// }

.facility.even .facility-overview {
	@include media-breakpoint-down(sm) {
		background: lighten($brand-primary, 8%);
	}
}

.facility-summary:hover .facility-toggle .ss-icon {
	color: $brand-primary;
}

.facility-summary:hover .facility-overview {
	background: darken($brand-primary, 8%);
}

.facility-overview ul {
	padding: 0;
	margin: 0;
}

.facility-overview ul li {
	list-style-type: none;
	display: inline-block;
	margin-right: 16px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 16px;
}

.facility-count {
	padding: 100px 15px 0;

	@include media-breakpoint-up(lg) {
		padding: 100px 40px 0;
	}
}

.facility-count .badge {
	background: #dddddd;
	font-size: 20px;
	border-radius: 50%;
	min-width: 48px;
	height: 48px;
	text-align: center;
	padding: 12px;
	vertical-align: middle;
	-webkit-transition: all .3s ease-out;
	-moz-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out;

	@include media-breakpoint-up(md) {
		min-width: 32px;
		height: 32px;
		font-size: 15px;
		padding: 8px;
	}

	@include media-breakpoint-up(lg) {
		min-width: 48px;
		height: 48px;
		font-size: 20px;
		padding: 12px;
	}
}

.facility-summary:hover .badge {
	background: $brand-primary;
}

.facility-count .label {
	color: $text-default;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	vertical-align: middle;
	font-weight: 300;
	margin-left: 22px;
}

.facility-group-nav {
	background: $tan;
	padding: 60px;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.facility-group-nav h2 {
	@extend .h3;
	margin: 0 0 10px;
}

.facility-group-nav ul {
	margin: 0 -60px;
	padding: 0;
}

.facility-group-nav ul li {
	list-style-type: none;
}

.facility-group-nav ul li a {
	padding: 11px 60px 10px;
	display: block;
	text-transform: uppercase;
	font-weight: 300;
	font-size: 14px;
	color: #222222;
	text-decoration: none;
	letter-spacing: 1px;
}

.facility-group-nav ul li a:hover {
	background: #ffffff;
	text-decoration: none;
}

.facility-group-nav ul li.active a {
	background: $brand-primary;
}

.facility-group-nav .badge {
	vertical-align: text-top;
	background: $brand-primary;
	color: $text-default;
	font-size: 10px;
	padding: 3px 6px 3px 6px;
	border-radius: 50px;
	text-align: center;
}

.facility-group-nav ul li.active .badge {
	background: white;
	color: $text-default;
}

.facility-content {
	padding: 60px;

	@include media-breakpoint-up(md) {
		max-height: 500px;
		overflow-y: auto;
	}

	@include media-breakpoint-up(lg) {
		max-height: 700px;
	}
}

.facility-content h3 {
	margin: 0 0 2px;
}

.facility-content .location {
	margin-bottom: 16px;
}

.filter-container {
	background: $gray-800;
	/*		padding-bottom: 15px;
*/
	.filter-select {
		background-color: $gray-800;
		color: $tan-light;
		border: none;
		/*padding: 0px 20px;*/
		appearance: none;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#fff' stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right;
		background-size: 1em;
	}

	@include media-breakpoint-down(sm) {
		.filter-select {
			padding: 10px 10px;
			text-align: center;
			margin: 0 auto;
			width: 80%;
		}
	}

	.filter-select option {
		background-color: $gray-800;
		color: $tan-light;
	}

	.filter-select option:nth-child(1) {
		text-align: center;
	}

	.button--link-dropdown {
		font-size: 0.75rem;
		color: #fff8e8;
	}
}

.filter-text-heading {
	color: $tan-light;
	margin-bottom: 0px;
}

.facility-filters {
	/*padding: 40px 0;*/

	&__buttons {
		display: flex;
		flex-flow: column;
		justify-content: space-evenly;
		align-items: center;
		padding: 20px;

		@include media-breakpoint-up(lg) {
			flex-flow: row;
		}

		a {
			text-align: center;
		}

		div {
			text-align: center;

			@include media-breakpoint-up(xs) {
				flex-direction: column;
			}

			@include media-breakpoint-up(lg) {
				flex-direction: initial;
			}
		}
	}

	.fiter-break-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}

		& .filter-break-line {
			border-left: 2px solid $tan-light;
			height: 40px;
		}

		p {
			margin: 1rem 0rem;
			color: $tan-light;
		}

		p:before,
		p:after {
			background-color: $tan-light;
			content: "";
			display: inline-block;
			height: 1px;
			position: relative;
			vertical-align: middle;
			width: 100%;
			/*	@include media-breakpoint-down($mediaBreakPointDesktop) {
				transform: rotate(0deg);
			}*/

			@media (min-width: $mediaBreakPointDesktop) {
				transform: rotate(90deg);
			}
		}

		p:before {
			margin-top: -100%;

			@include media-breakpoint-down(md) {
				margin-left: -50%;
				right: 1em;
				margin-top: 0;
				width: 35vw;
			}
		}

		p:after {
			margin-bottom: -100%;

			@include media-breakpoint-down(md) {
				margin-right: -50%;
				left: 1em;
				margin-bottom: 0;
				width: 35vw;
			}
		}
	}



	.dropdown {
		position: relative;
	}

	.dropdown-menu {
		/*background-color: white;*/
		background: #393732;
		color: #fff8e8;
		padding: 15px;
		transform: scaleY(0);
		max-height: 300px;
		overflow-y: scroll;

		&.show {
			transform: scaleY(1);
		}

		li a {
			font-family: "Gotham Medium";
			font-size: 0.75rem;
			line-height: 0.75rem;
			letter-spacing: 0.03125rem;
			text-transform: uppercase;
			position: relative;
			color: #fff8e8;
		}
	}

	.filter-clear-icon {
		font-weight: 400;
		font-size: 30px;
		display: flex;
		align-content: center;
		align-items: flex-end;
		justify-content: center;
		font-size: 30px;
		z-index: 20;
		cursor: pointer;
		color: $red;
		padding-left: 30px;
		transition: transform .3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	}

	.button--outline-white {
		font-size: 0.875rem;
		letter-spacing: 0.125rem;
		line-height: 1.5rem;
		border-radius: 50px;
		padding: 15px 15px;
		height: 50%;

		@include media-breakpoint-down(md) {
			/*width:50%;*/
			margin: 0 auto;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}
	}
}

.location-map {
	padding: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.facility {
	&-details {
		@include media-breakpoint-down(sm) {
			display: none;
			@include transition($transition-collapse);
		}

		&.show {
			display: flex;

			@include media-breakpoint-down(sm) {
				flex-direction: column-reverse;
			}
		}
	}
}



.map-page-content {
	.page-header {
		padding: 80px 0;
	}
}

#map {
	min-width: 100%;
	min-height: 400px;
	padding: 15px;

	#usmap {
		margin-bottom: -14%;

		@include media-breakpoint-up(xxl) {
			margin-bottom: -200px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: -8%;
		}
	}

	#camap {
		@include media-breakpoint-up(xxl) {
			margin-bottom: -100px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: -40%;
		}
	}
}

.tab-pane {
	min-width: 100%;
	min-height: 400px;

	&:not(.active.show) {
		position: absolute;
	}
}

.map-nav-items {
	margin-bottom: 70px;

	&.nav-tabs {
		border-bottom: 1px solid black;
	}

	.nav-link {
		font-family: $gotham-medium;
		font-size: .875rem;
		letter-spacing: .125rem;
		line-height: 1.25rem;
		background-color: $tan-light;
		color: black;
		border-color: transparent transparent black transparent;

		&.active, &[aria-selected='true'] {
			background-color: $tan-light;
			border-color: black black transparent black;
		}

		&:not(.active, [aria-selected='true']):hover {
			border-color: transparent transparent black transparent;
			cursor: pointer;
		}

		&:focus {
			outline: none;
		}
	}
}

.countries {
	display: flex;
	flex-flow: column;
	padding-bottom: 80px;

	@include media-breakpoint-up(lg) {
		flex-flow: row;
		justify-content: space-evenly;
	}

	@include media-breakpoint-down(md) {
		align-items: center;
	}

	&__country {
		@include media-breakpoint-up(lg) {
			//width: 45%;
		}
	}

	h3 {
		text-align: center;
	}

	&__map {
		max-width: 200px;
		display: block;

		svg {
			fill: $yellow;
			stroke: #fff;
			stroke-miterlimit: 4.93;
			stroke-opacity: 0;
			stroke-width: 3.7px;
			fill-rule: evenodd;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

#mx-map {
	min-height: 250px;
	margin-top: 10%;
	margin-left: -10%;
}

.countries__country {
	min-width: 250px;
}



#sw-map {
	margin-top: 30%;
	margin-bottom: 30%;
}

.scaling-svg-container {
	position: relative;
	height: 0;
	width: 100%;
	padding: 0;
	padding-bottom: 100%;
	/* override this inline for aspect ratio other than square */
}

.scaling-svg {
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}

.country_padding {
	@include media-breakpoint-down(md) {
		padding-bottom: 40px;
	}
}
