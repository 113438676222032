.nav-search{
    align-items: center;
    border-bottom: 1px solid $brand-primary;
    
    &__input,
    &__input:focus{
        font-family: "Gotham Light";
        padding-left: 0;
        font-size: 1.5rem;
        line-height: 2rem;
        color: $black;
        background-color: transparent;
        border: 0px solid transparent;
    }

    &__button{
        width: 30px;
        height: 30px;
        background-color: $brand-primary;
        color: $white;
        border: 0;
        padding: 6px 6px 5px 9px;
    }
}