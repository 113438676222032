.video-landing {
  position: relative;
  //height: 205vw;

  @include media-breakpoint-up(sm) {
    height: 85vw;
  }

  @include media-breakpoint-up(md) {
    height: 80vw;
  }

  @include media-breakpoint-up(lg) {
    height: 95vw;
  }

  @include media-breakpoint-up(xl) {
    height: 80vw;
    max-height: 1100px;
  }

  &__copy {
    max-width: 90%;
    text-align: center;
    margin: 70px auto 90px auto;
    z-index: 40;

    @include media-breakpoint-up(sm) {
      position: sticky;
      position: -webkit-sticky;
      bottom: -1px;
      top: 140px;
      margin: 80px auto 19vw auto;

      &.bottomed-out,
      &.bottomed-out .video-landing__kicker {
        color: $white !important;
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 70%;
      top: 180px;
      margin: 100px auto 22vw auto;
    }

    @include media-breakpoint-up(lg) {
      max-width: 650px;
      margin: 120px auto 16vw auto;
      top: 200px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 920px;
      margin: 120px auto 300px auto;
    }
  }

  &__title {
    transition: color 0.15s linear;
    font-size: 2.4rem;
    letter-spacing: -0.0625rem;
    line-height: 2.6rem;

    strong {
      color: $yellow;
      font-weight: 500;
    }

    @include media-breakpoint-up(lg) {
      font-size: 4.5rem;
      line-height: 4.375rem;
    }
  }

  &__kicker {
    transition: color 0.15s linear;
    margin-bottom: 0px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
    }
  }

  &__video {
    pointer-events: none;
    display: block;
    opacity: 0.75;
  }

  &__wrapper {
    background-color: $gray-dark;
    position: relative;
    bottom: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
      position: absolute;
    }
  }
}

.video {
  max-width: 100%;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    max-width: $max-width/3 * 2 !important;
  }
}
