.consent-banner {
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 30px;
    background-color: $gray-900;
    color: $white;
    border: none;
    margin: 15px auto;
    border-radius: 0;
    width: 1200px;
    max-width: 100%;

    &__button {
        @extend .button;
        border: none;
    }

    a {
        color: $brand-primary;

        &:hover {
            color: $white;
        }
    }

    &:not(.show),
    &.dismissed {
        display: none;
    }
}
