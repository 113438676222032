.sl-slide{
    
    display: flex;
    position: relative;
    padding: 80px 0;
    flex-flow: column-reverse;

    @include media-breakpoint-up(lg) {
        flex-flow: row;        
    }

    .sl-slide:after{
        content: '';
        top: 120px;
        display: block;
        position: absolute;
        width: 92%;
        height: calc(100% - 120px);
        z-index: 0;
        background-color: $orange;

        @include media-breakpoint-up(lg) {
            width: 80%;
            height: calc(100% - 320px);
            
        }
    }

    &__left{
        padding: 20px;
        z-index: 1;
        width: 92%;
        //padding-bottom: 240px;
        padding-bottom: 40px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        color: $white;
        position: relative;

        @include media-breakpoint-up(lg) {
            width: 50%;
            padding: 60px;
            padding-bottom: 140px;
        }
    }

    &__right{
        z-index: 2;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 50%;
            
        }
    }

    &__title{
        margin-bottom: 30px;
    }

    &__text{
        margin-bottom: 10px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }
    }

    &__image{
        width: 91%;
        max-width: 780px;
        height: 50vw;
        max-height: 777px;
        margin-top: 77px;
        object-fit: cover;
        float: right;

        object-position: var(--focal-left) var(--focal-top);

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-top: 77px;
            float: none;
        }
    }
}

.sl-slider{   
    margin: 60px auto 200px auto;

    &__controls{
        
        position: absolute;
        right: 0;
        display: flex;
        bottom: -40px;

        @include media-breakpoint-up(lg) {
            bottom: 120px;
        }
    }

    &__control{
        position: relative;
        width: 60px;
        height: 60px;
        margin-top:0;
        transition: all .15s linear;
        background-color: $black;

        .sl-slide:after{
            font-size: 1.75rem;
            color: $white;
        }

        &:hover{
            background-color: $white;
            .sl-slide:after{color: $black;}
        }
        &--prev{
            left:0;
        }

        &--next{
            right: 0;
        }
    }

    .sl-slide:after{
        content: '';
        top: 120px;
        display: block;
        position: absolute;
        width: 92%;
        height: calc(100% - 160px);
        z-index: 0;
        background-color: $orange;

        @include media-breakpoint-up(lg) {
            width: 80%;
            height: calc(100% - 320px);
        }
    }

    .swiper-button-prev,
    .swiper-button-next{

        &:after{
            color: $white;
            transition: all .15s linear;
        }
        &:hover{
            &:after{
            color: $black;
            }
        }
    }

    &--red{
        .sl-slide:after{
            background-color: $red;
        }
    }

    &--dark-red{
        .sl-slide:after{
            background-color: $red-dark;
        }
    }

    &--orange{
        .sl-slide:after{
            background-color: $orange;
        }
    }

    &--dark-orange{
        .sl-slide:after{
            background-color: $orange-dark;
        }
    }

    &--mustard{
        .sl-slide:after{
            background-color: $mustard;
        }
    }

    &--brown{
        .sl-slide:after{
            background-color: $mustard-dark;
        }
    }

    &--green{
        .sl-slide:after{
            background-color: $green;
        }
    }

    &--dark-green{
        .sl-slide:after{
            background-color: $green-dark;
        }
    }

    &--blue{
        .sl-slide:after{
            background-color: $blue;
        }
    }

    &--dark-blue{
        .sl-slide:after{
            background-color: $blue-dark;
        }
    }

    &--dark-gray{
        .sl-slide:after{
            background-color: $gray-dark;
        }
    }

    &--black{
        .sl-slide:after{
            background-color: $black;
        }

        .swiper-button-prev,
        .swiper-button-next{
            background-color: $white;
            &:after{
                color: $black;
                transition: all .15s linear;
            }
            &:hover{
                background-color: $gray-dark;
                &:after{
                color: $white;
                }
            }
        }
    }
}

.swiper-wrapper{
    &--singular{
        .sl-slider__controls{
            display: none;
        }
    }
}